import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Header from 'components/Header';
import SEO from 'components/seo';
import Link from 'components/Link';
import SectionTwo from 'components/Sections/SectionTwo';
import SSTradeSecOne from 'components/Sections/SSTradeSecOne';
import i18nx from 'components/locale/i18nx';


const PDFPage = ({
  data: {markdownRemark: { frontmatter }},
  pageContext,
}) => {

  return (
    <Layout>
      <SEO
        lang={pageContext.lang}
        title={frontmatter.title}
      />
      <Header lang={pageContext.lang}>
        <div className="ss_breadcrumb text-center">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h1>{frontmatter.title}</h1>
                <ul>
                  <li><Link to="/">{i18nx('home')}</Link><span> / {frontmatter.title}</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Header>
      <SSTradeSecOne page={frontmatter} />
      <SectionTwo page={frontmatter.features} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query Pdf($lang: String!) {
    markdownRemark(frontmatter: { lang: { eq: $lang }, templateType: { eq: "pdf"}}) {
      frontmatter {
        title
        wrapper
        label
        heading
        description
        button {
          label
          link
        }
        button_2 {
          label
          link
        }
        features {
          description
          image
          title
        }
      }
    }
  }
`

export default PDFPage;
