import React from 'react'


const SSTradeSecOne = ({ page }) => (
  <section className="ss_trade_sec_one spacer_bottom spacer_top">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-6">
          <div className="ss_td_one_left">
            <img className="img-fluid" src={page.wrapper} alt="wrapper" />
          </div>
        </div>
        <div className="col-lg-6 align-self-center">
          <div className="ss_td_one_right">
            <h3>{page.heading}</h3>
            {/* <h1>{page.heading}</h1> */}
            {/* <br /> */}
            <p>{page.description}</p>
            <a href={page.button.link} className="ss_btn mx-1">{page.button.label}</a>
            <a href={page.button_2.link} className="ss_btn mx-1">{page.button_2.label}</a>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default SSTradeSecOne;
